import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdCancel } from "react-icons/md";

const Nav = () => {
    const [hamburger, setHamburger] = useState(false);

    return (
        <div className='h-[10vh] flex items-center justify-between px-[20px] sticky top-0'>
            {/* Use react-router-dom's Link for navigation */}
            <Link to='/'>
                <h1 className='text-xl font-bold text-gray-300'>
                    Festum
                </h1>
            </Link>
            <div className='md:flex items-center justify-between hidden space-x-8'>
                {/* Update Link components */}
                <Link to='/login' className='text-gray-400 hover:text-white'>
                    Login
                </Link>
                <Link to='/userRegister' className='text-gray-400 hover:text-white'>
                    Register
                </Link>
                
            </div>
            <div className='md:hidden block'>
                <GiHamburgerMenu
                    className='cursor-pointer text-2xl text-gray-400'
                    onClick={() => setHamburger(true)}
                />
            </div>
            {hamburger && (
                <nav className='fixed top-0 right-0 w-1/2 dim h-[100vh] p-6'>
                    <div className='w-full flex items-center justify-end mb-8'>
                        <MdCancel
                            className='text-4xl text-[#C07F00] cursor-pointer hover:text-white'
                            onClick={() => setHamburger(false)}
                        />
                    </div>
                    <div className='flex w-full flex-col space-y-8'>
                        {/* Update Link components */}
                        <Link to='/login' className='text-white hover:text-[#C07F00]'>
                            Login
                        </Link>
                        <Link to='/register' className='text-white hover:text-[#C07F00]'>
                            Register
                        </Link>
                       
                    </div>
                </nav>
            )}
        </div>
    );
};

export default Nav;
