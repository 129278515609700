import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react'
import {  getUserEvents } from '../utils/util';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../utils/firebase';
import Loading from '../components/Loading';
import AuthNav from '../components/AuthNav';
import Events from '../components/Events';
import NoEventsReg from '../components/NoEventsReg';
import UserEvents from '../components/UserEvents';
import { Helmet } from 'react-helmet';

export default function UserPage() {


    const router = useNavigate();
	

	const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);

	const [events, setEvents] = useState([]);
    const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	// const { query } = useNavigate();
	const isUserLoggedIn = useCallback(() => {
        setLoading(true);
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser({ email: user.email, uid: user.uid });
				setName(auth.currentUser.displayName);
		setEmail(auth.currentUser.email);
        getUserEvents(user.uid, setEvents, setLoading);
			} else {
				return router("/userRegister");
			}
		});
	}, []);

	useEffect(() => {
		isUserLoggedIn();
		
	}, [isUserLoggedIn]);

    if (loading) {
		return <Loading title='Getting your tickets🤞🏼' />;
	}
  return (
    <div>
			<Helmet>
				<title>Dashboard | Festum</title>
				<meta
					name='description'
					content='An event ticketing system'
				/>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<link rel='icon' href='/favicon.ico' />
			</Helmet>
			<main>
				<AuthNav user={user} />
				{events.length > 0 ? <UserEvents events={events} /> : <NoEventsReg />}
			</main>
		</div>
  )
}
