import React, { useState, useEffect } from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import { HiMail } from "react-icons/hi";
import { AiTwotoneLock, AiFillCheckCircle } from "react-icons/ai";
import { firebaseCreateUser } from "../utils/util";
import { useNavigate } from "react-router-dom";
import { signInWithGoogle } from "../utils/firebase";
import { Helmet } from "react-helmet";

const UserRegisterPage = () => {
	const [email, setEmail] = useState("");
	const [cpassword, setCPassword] = useState("");
	const navigate = useNavigate();
	const location = useLocation();
	const search = location.search; // "?url=bhxcyTr7dju7FsTvom37/test-2"
	
	const queryParams = new URLSearchParams(search);
const url = queryParams.get('url'); // "bhxcyTr7dju7FsTvom37/test-2"
console.log(url);
	const handleSubmit = (e) => {
        signInWithGoogle(url,navigate);
		// e.preventDefault();
		// if (password === cpassword) {
		// 	firebaseCreateUser(email, password, router);
		// 	setEmail("");
		// 	setPassword("");
		// 	setCPassword("");
		// }
	};

	
	return (
		<div>
			<Helmet>
				<title>Register | Festum</title>
				<meta
					name='description'
					content='An event ticketing system built with NextJS and Firebase'
				/>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<link rel='icon' href='/favicon.ico' />
			</Helmet>
			<main className='w-full flex items-center justify-between min-h-[100vh]'>
				<div className='md:w-[60%] w-full flex flex-col items-center justify-center min-h-[100vh] px-[30px] py-[30px] relative'>
					<Link href='/'>
						<h2 className='text-2xl font-medium mb-6'>Create an account</h2>
					</Link>
					<button
							// type='submit' o
                            onClick={()=>{
                                signInWithGoogle(url,navigate);
                            }}
							className='bg-[#FFD95A] p-3 font-medium hover:bg-[#C07F00] hover:text-[#FFF8DE] mb-3 rounded-md'
						>
							Login With Google
						</button>
					<div className='absolute bottom-5 left-5'>
						<p className='opacity-50 text-sm'>
							<Link href='/'>Festum</Link> &copy; Copyright{" "}
							{new Date().getFullYear()}{" "}
						</p>
					</div>
				</div>
				<div className='register md:w-[40%] h-[100vh] relative'>
					
				</div>
			</main>
		</div>
	);
};

export default UserRegisterPage;
