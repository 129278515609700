import React, { useState } from "react";
import { useTable } from "react-table";

const Attendees = ({ attendees }) => {
    const [attendeeState, setAttendees] = useState(attendees);
    const data = React.useMemo(() => attendeeState, [attendeeState]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name", // Accessor is the "key" in the data
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Mobile",
                accessor: "mobile",
            },
            {
                Header: "Transaction Time",
                accessor: "transactionTime",
                Cell: ({ value }) => {
                    // Format the transaction time as a readable date
                    return `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`;
                },
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div className='bg-white w-full p-8'>
            <h2 className='text-3xl font-bold mb-6'>List of Attendees</h2>

            <div className='overflow-y-scroll max-h-[450px]'>
                <table {...getTableProps()} className='w-full'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className='bg-gray-100 sticky top-0 p-2 text-left text-sm font-semibold text-gray-700'>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} className='p-2 border-t border-gray-100 text-sm text-gray-700'>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Attendees;
