import React from 'react'
import fourfour from "../images/fourfour.svg"
// import Image from 'next/image';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
const ErrorPage = () => {
  return (
		<div>
			<Helmet>
				<title> 404 | Festum</title>
				<meta
					name='description'
					content='An event ticketing system built with NextJS and Firebase'
				/>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<link rel='icon' href='/favicon.ico' />
			</Helmet>
			<main className='w-full h-[100vh] flex flex-col space-y-4 items-center justify-center'>
				<img src={fourfour} alt='404 page' className='w-[400px] mb-8' />
				<h2 className='text-xl text-[#C07F00]'>Seems we lost you! 😪</h2>
				<Link
					href='/'
					className='p-4 bg-slate-100 shadow-md hover:bg-[#C07F00] hover:text-slate-100 rounded-md'
				>
					Come back home 🏠
				</Link>
			</main>
		</div>
	);
}

export default ErrorPage