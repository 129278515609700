import React, { useCallback, useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { doc, getDoc } from "@firebase/firestore";
import db, { auth } from "../../../utils/firebase";
import { getEvents, registerAttendee } from "../../../utils/util";
import { useNavigate } from "react-router-dom";
import RegClosed from "../../../components/RegClosed";
import ErrorPage from "../../../components/ErrorPage";
import Loading from "../../../components/Loading";
import { onAuthStateChanged } from "firebase/auth";
import { Helmet } from "react-helmet";



const RegisterPage = () => {


	const router = useNavigate();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [rollNo, setRollNo] = useState("");
	const [clg, setClg] = useState("");
	const [mobile, setMobile] = useState("");

	const [user, setUser] = useState({});
	const [events, setEvents] = useState([]);
	const [event, setEvent] = useState({}); // State to hold the fetched event
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const { id ,slug} = useParams(); 
	const [paymentUrl, setPaymentUrl] = useState(''); // New state for storing payment URL
	const [showPaymentPopup, setShowPaymentPopup] = useState(false); // New state for showing the payment popup

	useEffect(() => {
setLoading(true);
		const fetchEvent = async () => {
			
			const docRef = doc(db, "events", id);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setEvent(docSnap.data());
				setLoading(false);
			} else {
				console.log("No such document!");
			}
		};

		if (id) {
			fetchEvent();
		}
	}, [id]);


	

	const isUserLoggedIn = useCallback(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser({ email: user.email, uid: user.uid });
				setName(auth.currentUser.displayName);
		setEmail(auth.currentUser.email);
				getEvents(user.uid, setEvents, setLoading);
			} else {
				 router(`/userRegister?url=`+id+"/"+slug);
			}
		});
	}, []);

	useEffect(() => {
		isUserLoggedIn();
		
	}, [isUserLoggedIn]);
	

	
	

    const handlePayment = (e)=>{
        
    }
	async function makePayment() {
		setLoading2(true);

    try {
      const response = await fetch(`https://us-central1-saola-innovations.cloudfunctions.net/api/pay?amount=${event.cost}&name=${name}&email=${email}&rollNumber=${rollNo}&eventId=${id}&mobile=${mobile}&clg=${clg}&uId=${user.uid}`, {
        method: 'GET', 
      });
      const data = await response.text();
      
      
	  
	  console.log(JSON.parse(data).data.instrumentResponse.redirectInfo.url);
	  const url = JSON.parse(data).data.instrumentResponse.redirectInfo.url;
	  window.location.replace( url);
	  setLoading2(false);
	
	setPaymentUrl(url);
	// setShowPaymentPopup(true);
	// router(url); 
    } catch (error) {
      console.error('Failed to fetch:', error);
    } finally {
      setLoading(false);
    }
	  };
	


	// const makePayment1 =()=>{
	// 	// e.preventDefault();
    //     setLoading2(true);
    //     axios.post('http://localhost:3002/pay?amount=540').then(res => {  
    //     setTimeout(() => {
    //         setLoading2(false);
    //     }, 1500);
    //     })
    //     .catch(error => {
    //         setLoading2(false)
    //         console.error(error);
    //     });   

	// 	// registerAttendee(name, email, query.id, setSuccess, setLoading);

	// }

	// const handleSubmit = async (e) => {
	// 	e.preventDefault();
	// 	await makePayment();
	
	// };

	const handleSubmit = async (e) => {
		e.preventDefault();
	
		// Simple validation for roll number and mobile number
		// const rollNumberPattern = /^\d{5}[A-Z]{2}\d{4}$/; // Example pattern: 20071A04M1
		const mobileNumberPattern = /^\d{10}$/; // Validates an Indian mobile number of 10 digits
	
		// if (!rollNumberPattern.test(rollNo)) {
		// 	alert("Invalid roll number format. Expected format: 20071A04M1");
		// 	return; // Stop the form submission
		// }
	
		if (!mobileNumberPattern.test(mobile)) {
			alert("Invalid mobile number. Mobile number must be 10 digits.");
			return; // Stop the form submission
		}
	
		// Proceed with payment if validation passes
		await makePayment();
	};

	

	if (loading) {
		return <Loading title='Generating your ticket🤞🏼' />;
	}
	if (loading2) {
		return <Loading title='Initiating your Payment🤞🏼' />;
	}
	if (!event.title) {
		return <ErrorPage />;
	}

	if (event.disableRegistration) {
		return <RegClosed event={event} />;
	}

	return (
		<div>
			<Helmet>
				<title>{`${event.title} | Festum`}</title>
				<meta
					name='description'
					content='An event ticketing system by Saola Innovations'
				/>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<link rel='icon' href='/favicon.ico' />
			</Helmet>

			{showPaymentPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg relative">
            {/* <iframe src={"https://google.co.in"} title="Payment" className="h-full max-h-[640px] z-[2147000000] border-none fixed w-full max-w-[360px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 shadow-[3px_3px_25px_-3px_rgba(0,0,0,0.45)] rounded-lg" ></iframe> */}
            Pay Using PhonePe
			<a href={paymentUrl} >
				<button
              onClick={() => setShowPaymentPopup(false)}
              className="absolute top-0 right-0 p-2">
             
            </button>
			Pay Using PhonePe</a>
          </div>
        </div>
      )}


			<main className='w-full flex items-center justify-between min-h-[100vh] relative'>
				<div className='md:w-[60%] w-full flex flex-col items-center justify-center min-h-[100vh] px-[30px] py-[30px] relative'>
					<h2 className='text-2xl font-medium mb-3'>Get your ticket 🎉</h2>
					<form
						className='w-full flex flex-col justify-center'
						onSubmit={handleSubmit}
					>
						<label htmlFor='name'>Full name</label>
						<div className='w-full relative'>
							<input
								type='text'
								name='name'
								value={name}
								onChange={(e) => setName(e.target.value)}
								className='border px-10 py-2 mb-3 rounded-md w-full'
								required
							/>
							<FaUserAlt className=' absolute left-4 top-3 text-gray-300' />
						</div>


						<label htmlFor='rollNo'>Roll Number</label>
						<div className='w-full relative'>
							<input
								type='text'
								name='name'
								value={rollNo}
								onChange={(e) => setRollNo(e.target.value)}
								className='border px-10 py-2 mb-3 rounded-md w-full'
								required
							/>
							<FaUserAlt className=' absolute left-4 top-3 text-gray-300' />
						</div>

						<label htmlFor='mobile'>Mobile Number</label>
						<div className='w-full relative'>
							<input
								type='text'
								name='name'
								value={mobile}
								onChange={(e) => setMobile(e.target.value)}
								className='border px-10 py-2 mb-3 rounded-md w-full'
								required
							/>
							<FaUserAlt className=' absolute left-4 top-3 text-gray-300' />
						</div>
						<label htmlFor='clg'>College Name</label>
						<div className='w-full relative'>
							<input
								type='text'
								name='name'
								value={clg}
								onChange={(e) => setClg(e.target.value)}
								className='border px-10 py-2 mb-3 rounded-md w-full'
								required
							/>
							<FaUserAlt className=' absolute left-4 top-3 text-gray-300' />
						</div>
						<label htmlFor='email'>Email address</label>
						<div className='w-full relative'>
							<input
								type='email'
								name='email'
								value={email}
								// onChange={(e) => setEmail(e.target.value)}
								className='border px-10 py-2 mb-3 rounded-md w-full'
								required
							/>
							<HiMail className=' absolute left-4 top-3 text-gray-300 text-xl' />
						</div>
						<button
							type='submit'
							className='bg-[#FFD95A] p-3 font-medium hover:bg-[#C07F00] hover:text-[#FFF8DE] mb-3 rounded-md'
						>
							Buy Ticket
						</button>
					</form>
					<div className='absolute bottom-5 left-5'>
						<p className='opacity-50 text-sm'>
							<Link href='/'>{event.title}</Link> &copy; Copyright{" "}
							{new Date().getFullYear()}{" "}
						</p>
					</div>
				</div>
				<div className='login md:w-[40%] h-[100vh] relative'>
					
				</div>
				{success && (
					<div className='w-full h-[100vh] dim absolute top-0 flex items-center justify-center z-40'>
						<div className='w-[400px] bg-white h-[300px] flex items-center justify-center flex-col rounded-md shadow-[#FFD95A] shadow-md'>
							<h2 className='text-2xl font-extrabold mb-4 text-center'>
								Registered Successfully! 🎉
							</h2>
							<p className='text-center mb-6'>
								Check your email for your ticket and event information.
							</p>
							<button
								className='px-4 py-2 bg-[#FFD95A] rounded-md'
								onClick={() => setSuccess(false)}
							>
								OK
							</button>
						</div>
					</div>
				)}
			</main>
		</div>
	);
};
export default RegisterPage;
