import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../components/Nav"; // Ensure these components are adapted for React if they use Next.js features
import Hero from "../components/Hero";
import HomeSection from "../components/HomeSection";
import Footer from "../components/Footer";

export default function Home() {
    return (
        <>
            <Helmet>
                <title>Festum</title>
                <meta name="description" content="An event ticketing system built with React and Firebase" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <main className="h-[100vh] home">
                <Nav />
                <Hero />
                <HomeSection />
                <Footer />
            </main>
        </>
    );
}
