import {
	signInWithEmailAndPassword,
	signOut,
	createUserWithEmailAndPassword,
} from "firebase/auth";
import { toast } from "react-toastify";
import {
	getDownloadURL,
	ref,
	uploadString,
	deleteObject,
} from "@firebase/storage";
import db, { storage, auth ,rdb}  from "./firebase";
import { ref as ref1, get, child, query as query2, equalTo } from 'firebase/database';


import {
	getDoc,
	addDoc,
	collection,
	doc,
	updateDoc,
	onSnapshot,
	query,
	deleteDoc,
	where,
	arrayUnion,
} from "@firebase/firestore";
import axios from "axios";
// import { useRouter } from "next/router";



// const sendEmail = (
// 	name,
// 	email,
// 	title,
// 	time,
// 	date,
// 	note,
// 	description,
// 	passcode,
// 	flier_url,
// 	setSuccess,
// 	setLoading
// ) => {
// 	emailjs
// 		.send(
// 			process.env.NEXT_PUBLIC_SERVICE_ID,
// 			process.env.NEXT_PUBLIC_TEMPLATE_ID,
// 			{
// 				name,
// 				email,
// 				title,
// 				time: convertTo12HourFormat(time),
// 				date,
// 				note,
// 				description,
// 				passcode,
// 				flier_url,
// 			},
// 			process.env.NEXT_PUBLIC_API_KEY
// 		)
// 		.then(
// 			(result) => {
// 				setLoading(false);
// 				setSuccess(true);
// 			},
// 			(error) => {
// 				alert(error.text);
// 			}
// 		);
// };

export const generateID = () => Math.random().toString(36).substring(2, 10);
export const createSlug = (sentence) => {
	let slug = sentence.toLowerCase().trim();
	slug = slug.replace(/[^a-z0-9]+/g, "-");
	slug = slug.replace(/^-+|-+$/g, "");
	return slug;
};

export const addEventToFirebase = async (
	id,
	title,
	date,
	time,
	venue,
	description,
	note,
	flier,
	router
) => {
	const docRef = await addDoc(collection(db, "events"), {
		user_id: id,
		title,
		date,
		time,
		venue,
		description,
		note,
		slug: createSlug(title),
		attendees: [],
		disableRegistration: false,
	});

	const imageRef = ref(storage, `events/${docRef.id}/image`);

	if (flier !== null) {
		await uploadString(imageRef, flier, "data_url").then(async () => {
			//👇🏻 Gets the image URL
			const downloadURL = await getDownloadURL(imageRef);
			//👇🏻 Updates the docRef, by adding the logo URL to the document
			await updateDoc(doc(db, "events", docRef.id), {
				flier_url: downloadURL,
			});

			//Alerts the user that the process was successful
			successMessage("Event created! 🎉");
			router("/dashboard");
		});
	} else {
		successMessage("Event created! 🎉");
		router("/dashboard");
	}
};
export const sendEmail = async (name,email,router) => {
    // setIsLoading(true);
    try {

		// router = useRouter();
        const response = await axios.post('http://localhost:3002/send-email', { name, email });
        // console.log(response.data);
        // router.replace("/success");
        // alert('Email sent successfully!');

      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to send email.');
      }
	  router.push("/user");
  };

export const addTicketToFirebase = async (
	day_1,
	event_id,
	payment_id,
	status,
	user_id,
	name,
	email,
	// description,
	// note,
	// flier,
	router
) => {
	const docRef = await addDoc(collection(db, "tickets"), {
		user_id,
		day_1,
	event_id,
	payment_id,
	status,
		
	});

	// const imageRef = ref(storage, `events/${docRef.id}/image`);

		successMessage("Ticekt Booked! 🎉");
		sendEmail(name,email,router);

		
	
};



export const successMessage = (message) => {
	toast.success(message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	});
};
export const errorMessage = (message) => {
	toast.error(message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	});
};

export const firebaseCreateUser = (email, password, router) => {
	createUserWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			const user = userCredential.user;
			successMessage("Account created 🎉");
			router.push("/login");
		})
		.catch((error) => {
			console.error(error);
			errorMessage("Account creation declined ❌");
		});
};
export const firebaseLoginUser = (email, password, router) => {
	signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			const user = userCredential.user;
			successMessage("Authentication successful 🎉");
			router("/dashboard");
		})
		.catch((error) => {
			console.error(error);
			errorMessage("Incorrect Email/Password ❌");
		});
};

export const firebaseLogOut = (router) => {
	signOut(auth)
		.then(() => {
			successMessage("Logout successful! 🎉");
			router.push("/");
		})
		.catch((error) => {
			errorMessage("Couldn't sign out ❌");
		});
};

export const getEvents = (id, setEvents, setLoading) => {
	try {
		const q = query(collection(db, "events"), where("user_id", "==", id));

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			const firebaseEvents = [];
			querySnapshot.forEach((doc) => {
				firebaseEvents.push({ data: doc.data(), id: doc.id });
			});
			console.log(firebaseEvents);
			setEvents(firebaseEvents);
			setLoading(false);

			return () => unsubscribe();
		});
	} catch (error) {
		console.error(error);
	}
};


export const getEventInfo = (id, setEvents, setLoading,payment_id) => {
	try {
		const q = query(collection(db, "events"));

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			const firebaseEvents = [];
			querySnapshot.forEach((doc) => {
				if(doc.id==id){
					firebaseEvents.push({ data: doc.data(), id: doc.id ,payment_id:payment_id});
				}
				
			});
			console.log(firebaseEvents);
			setEvents(firebaseEvents);
			setLoading(false);

			return () => unsubscribe();
		});
	} catch (error) {
		console.error(error);
	}
};


// export const getUserEvents = (id, setEvents, setLoading) => {
// 	try {
// 		const q = query(collection(db, "tickets"), where("user_id", "==", id));

// 		const unsubscribe = onSnapshot(q, (querySnapshot) => {
// 			// const firebaseEvents = [];
// 			querySnapshot.forEach((doc) => {
// 			console.log(doc.data().event_id);

// 				// firebaseEvents.push({ data: doc.data(), id: doc.id });
// 				getEventInfo(doc.data().event_id,setEvents,setLoading,doc.data().payment_id);
// 			});

			
// 			// console.log(firebaseEvents);
// 			// setEvents(firebaseEvents);
// 			setLoading(false);

// 			return () => unsubscribe();
// 		});
// 	} catch (error) {
// 		console.error(error);
// 	}
// };

export const getUserEvents = (id, setEvents, setLoading) => {
	setLoading(true);
	  
	// try {
	 
	//   // Reference to the 'tickets' node in your Realtime Database
	//   const ticketsRef = ref1(rdb, 'payments',id);
	  
	//   // Query to find tickets where 'user_id' equals the provided id
	//   const q = query2(ticketsRef);
	  
	//   get(q).then((snapshot) => {
	// 	if (snapshot.exists()) {
	// 	  const events = [];
		  
	// 	  snapshot.forEach((childSnapshot) => {
	// 		const ticketData = childSnapshot.val();
	// 		console.log(childSnapshot.val());
			
	// 		// Assuming getEventInfo is an asynchronous function that fetches event info and updates the state
		
	// 		// If you want to collect all event data before setting the state, you'd push to 'events' here
	// 		// events.push({ data: ticketData, id: childSnapshot.key });
	// 	  });

		  

		  

		  
	// 	  // If collecting all data first, set state here
	// 	  // setEvents(events);
	// 	  setLoading(false);
	// 	} else {
	// 	  console.log("No data available");
	// 	  setLoading(false);
	// 	}
	//   }).catch((error) => {
	// 	console.error(error);
	// 	setLoading(false);
	//   });
	// } catch (error) {
	//   console.error(error);
	//   setLoading(false);
	// }
console.log(id);

	const userPaymentsRef = ref1(rdb, `payments/${id}`);

	get(userPaymentsRef).then((snapshot) => {
	  if (snapshot.exists()) {
		const payments = snapshot.val();
		const paymentIds = Object.keys(payments); // Extract the keys, which are the payment IDs
  
		console.log("Payment IDs:", paymentIds);
		
		// If you need to work with each payment's data:
		paymentIds.forEach((paymentId) => {
		  console.log(`Payment ID: ${paymentId}, Payment Data:`, );
		  getEventInfo(payments[paymentId].eventId, setEvents, setLoading, payments[paymentId].merchantTransactionId);
			
		  // Here, you could call another function to process each payment's data
		  // For example: processPaymentData(paymentId, payments[paymentId]);
		});
	  } else {
		console.log("No payments found for this user.");
		setLoading(false);
	  }
	}).catch((error) => {
	  console.error("Error fetching user payments:", error);
	  setLoading(false);
	});


  };




export const convertTo12HourFormat = (time) => {
	const [hours, minutes] = time.split(":").map(Number);
	const period = hours >= 12 ? "pm" : "am";
	const hours12 = hours % 12 || 12;
	const formattedTime = `${hours12.toString().padStart(2, "0")}:${minutes
		.toString()
		.padStart(2, "0")}`;
	return `${formattedTime}${period}`;
};

export const updateRegLink = async (id) => {
	const number = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
	const eventRef = doc(db, "events", id);
	updateDoc(eventRef, {
		disableRegistration: true,
		slug: `expired-${number}`,
	});
};
export const registerAttendee = async (
	name,
	email,
	event_id,
	setSuccess,
	setLoading
) => {
	setLoading(true);
	const passcode = generateID();
	const eventRef = doc(db, "events", event_id);
	const eventSnap = await getDoc(eventRef);
	let firebaseEvent = {};
	if (eventSnap.exists()) {
		firebaseEvent = eventSnap.data();
		const attendees = firebaseEvent.attendees;
		const result = attendees.filter((item) => item.email === email);
		if (result.length === 0 && firebaseEvent.disableRegistration === false) {
			await updateDoc(eventRef, {
				attendees: arrayUnion({
					name,
					email,
					passcode,
				}),
			});
			const flierURL = firebaseEvent.flier_url
				? firebaseEvent.flier_url
				: "No flier for this event";
			sendEmail(
				name,
				email,
				firebaseEvent.title,
				firebaseEvent.time,
				firebaseEvent.date,
				firebaseEvent.note,
				firebaseEvent.description,
				passcode,
				flierURL,
				setSuccess,
				setLoading
			);
		} else {
			setLoading(false);
			errorMessage("User already registered ❌");
		}
	}
};

export const deleteEvent = async (id) => {
	await deleteDoc(doc(db, "events", id));

	const imageRef = ref(storage, `events/${id}/image`);
	deleteObject(imageRef)
		.then(() => {
			console.log("Deleted successfully");
		})
		.catch((error) => {
			console.error("Image does not exist");
		});
};
