import React, { useCallback, useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import db, { auth } from '../../../utils/firebase';
import Nav from '../../../components/Nav';
import AuthNav from '../../../components/AuthNav';
import Loading from '../../../components/Loading';
import { onAuthStateChanged } from 'firebase/auth';

const ListEvent = () => {
//   const [daysData, setDaysData] = useState({ day1: { in: 0, notIn: 0 }, day2: { in: 0, notIn: 0 }, day3: { in: 0, notIn: 0 } });


  const {id,slug} = useParams();
  console.log(id);
  const [daysData, setDaysData] = useState({
    day1: { registered: 0, in: 0, notIn: 0 },
    day2: { registered: 0, in: 0, notIn: 0 },
    day3: { registered: 0, in: 0, notIn: 0 }
  });


  const [loading, setLoading] = useState(false);

  const router = useNavigate();
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const { query } = useNavigate();
  const isUserLoggedIn = useCallback(() => {
	//   setLoading(true);
	  onAuthStateChanged(auth, (user) => {
		  if (user && user.email==="info@saola.in") {
			  setUser({ email: user.email, uid: user.uid });
			  setName(auth.currentUser.displayName);
	  setEmail(auth.currentUser.email);
	//   getUserEvents(user.uid, setEvents, setLoading);
		  } else {
			  return router("/userRegister");
		  }
	  });
  }, []);

  useEffect(() => {
	  isUserLoggedIn();
	  
  }, [isUserLoggedIn]);



  // useEffect(() => {
  //   const fetchData = async () => {
	// 	setLoading(true);
  //     const docRef = doc(db, 'tickets', id);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       const data = docSnap.data();
	// 	console.log(data);
  //       const tempData = {
  //         day1: { registered: 0, in: 0, notIn: 0 },
  //         day2: { registered: 0, in: 0, notIn: 0 },
  //         day3: { registered: 0, in: 0, notIn: 0 }
  //       };

  //       Object.entries(data).forEach(([key, value]) => {
  //         ['day1', 'day2', 'day3'].forEach(day => {
  //           if (value[day] === 2) {
  //             tempData[day].in++;
  //             tempData[day].registered++;
  //           } else if (value[day] === 1) {
  //             tempData[day].notIn++;
  //             tempData[day].registered++;
  //           }
  //           // '-1' values are ignored, not adding to registered count
  //         });
  //       });

  //       setDaysData(tempData);
	// 	setLoading(false);
  //     } else {
  //       console.log("No such document!");
  //     }
  //   };

  //   fetchData();
  // }, [id]);

  // useEffect(() => {
  //   const fetchData = async (currentId, path = '') => {
  //     setLoading(true);
  //     let fullPath = path ? `${path}/${currentId}` : `tickets/${currentId}`;
  //     const docRef = doc(db, fullPath);
  //     const docSnap = await getDoc(docRef);
  //     const tempData = {
  //       day1: { registered: 0, in: 0, notIn: 0 },
  //       day2: { registered: 0, in: 0, notIn: 0 },
  //       day3: { registered: 0, in: 0, notIn: 0 }
  //     };
  //     if (docSnap.exists()) {
  //       const data = docSnap.data();
  //       console.log(data);
  //       // Initialize or update your tempData structure here
  //       // Assuming tempData is defined outside this function
  //       // to aggregate data across calls
 
  //       Object.entries(data).forEach(([key, value]) => {
  //         ['day1', 'day2', 'day3'].forEach(day => {
  //           if (value[day] === 2) {
  //             tempData[day].in++;
  //             tempData[day].registered++;
  //           } else if (value[day] === 1) {
  //             tempData[day].notIn++;
  //             tempData[day].registered++;
  //           }
  //           // '-1' values are ignored
  //         });
  //       });
  
  //       // Attempt to access a subcollection with the same ID as the document
  //       // This part assumes you know or expect a subcollection to exist
  //       const subCollectionRef = collection(db, `${fullPath}/${currentId}`);
  //       const subCollectionSnap = await getDocs(subCollectionRef);
  //       if (!subCollectionSnap.empty) {
  //         // Assuming only one doc with the same ID in each collection for simplicity
  //         await fetchData(currentId, `${fullPath}`);
  //       }
  //     } else {
  //       console.log("No such document!");
  //     }
  
  //     // Update state once after all recursive calls complete if this is the initial call
  //     if (path === '') {
  //       setDaysData(tempData);
  //       setLoading(false);
  //     }
  //   };
  
  //   fetchData(id);
  // }, [id]);
  
  useEffect(() => {
    const tempData = {
      day1: { registered: 0, in: 0, notIn: 0 },
      day2: { registered: 0, in: 0, notIn: 0 },
      day3: { registered: 0, in: 0, notIn: 0 }
    };

    const fetchData = async (currentId, collectionPath = 'tickets') => {
      setLoading(true);
      const docRef = doc(db, collectionPath, currentId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log(data);

        Object.entries(data).forEach(([key, value]) => {
          ['day1', 'day2', 'day3'].forEach(day => {
            if (value[day] === "2") {
              tempData[day].in++;
              tempData[day].registered++;
            } else if (value[day] === "1") {
              tempData[day].notIn++;
              tempData[day].registered++;
            }
            // '-1' values are ignored
          });
        });

        // Attempt to access a subcollection named after the document
        const subCollectionPath = `${collectionPath}/${currentId}/${currentId}`;
        const subCollectionRef = collection(db, subCollectionPath);
        const subCollectionSnap = await getDocs(subCollectionRef);

        if (!subCollectionSnap.empty) {
          // Recursively fetch data from the subcollection
          // This assumes subcollection documents have the same ID pattern
          for (const doc of subCollectionSnap.docs) {
            await fetchData(doc.id, subCollectionPath);
          }
        }
      } else {
        console.log("No such document!");
      }

      if (collectionPath === 'tickets') { // Only update state in the initial call
        setDaysData(tempData);
        setLoading(false);
      }
    };

    fetchData(id);
  }, [id]); // Dependency array ensures this effect runs when `id` changes

  if (loading) {
	return <Loading title='Getting  tickets Data🤞🏼' />;
}

  return (
	<div>

<AuthNav user={user} />
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
      {Object.entries(daysData).map(([day, { in: inCount, notIn }], index) => (
        <div key={index} className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-bold mb-2">{`Day ${index + 1}`}</h2>
          <p>{`Total Registered: ${inCount + notIn}`}</p>
          <p>{`In: ${inCount}`}</p>
          <p>{`Not In: ${notIn}`}</p>
        </div>
      ))}
    </div>
	</div>
  );
};

export default ListEvent;
