import React, { useEffect, useState, useCallback } from "react";
import { AiTwotoneHome } from "react-icons/ai";
import { BsFillShareFill } from "react-icons/bs";
import Attendees from "../../../components/Attendees";
import { doc, getDoc } from "@firebase/firestore";
import db, { auth } from "../../../utils/firebase";
import ShareEventModal from "../../../components/ShareEventModal";
import ErrorPage from "../../../components/ErrorPage";
import axios from "axios";
import { addTicketToFirebase, errorMessage } from "../../../utils/util";
import { onAuthStateChanged } from "firebase/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import party from "../../../images/processing.gif";
import Footer from "../../../components/Footer";


const PaymentDone = () => {
  const router = useNavigate();



  const {  id,slug } = useParams();
  const navigate = useNavigate();
	const location = useLocation();
	const search = location.search; // "?url=bhxcyTr7dju7FsTvom37/test-2"
// /info?name=${name}&email=${email}&rollNumber=${rollNumber}&eventId=${eventId}&mobile=${mobile}&clg=${clg}`,

	const queryParams = new URLSearchParams(search);
const name = queryParams.get('name'); 
const email = queryParams.get('email'); 
const mobile = queryParams.get('mobile'); 
const eventId = queryParams.get('eventId'); 
const clg = queryParams.get('clg'); 
const rollNumber = queryParams.get('rollNumber'); 
const uid = queryParams.get('uId'); 

console.log(name,email,mobile,eventId,clg,rollNumber);
  const [user, setUser] = useState({});
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const validatePayment = async () => {
    try {
      const response = await fetch(`https://us-central1-saola-innovations.cloudfunctions.net/api/validate/${id}?name=${name}&email=${email}&rollNumber=${rollNumber}&eventId=${eventId}&mobile=${mobile}&clg=${clg}&uId=${uid}`, {
        method: 'GET',
      });
      const data = await response.json();
      console.log(data);
      setPaymentStatus(data);
      if(data.code === "PAYMENT_SUCCESS") {
         router(`/success`);
      } else if(data.code === "PAYMENT_ERROR") {
        errorMessage("Payment Failed");

        router(`/failure/?${id}/failRevert`);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
       
      } else {
        router.push("/register");
      }
    });
  }, [id]);

  // if(loading) {
  //   return <div>Loading...</div>; // Consider using a more robust loading indicator
  // }
	// const [click, setClick] = useState(firebaseEvent.disableRegistration);
	// const [showModal, setShowModal] = useState("");
	// const [disableRegModal, setDisableRegModal] = useState(false);
	// const openModal = () => setShowModal(true);
	// const closeModal = () => setShowModal(false);
	// const router = useRouter();

	// if (!firebaseEvent.title) return <ErrorPage />;
	
	const [events, setEvents] = useState([]);
	

	const isUserLoggedIn = useCallback(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser({ email: user.email, uid: user.uid });
        setUser({ email: user.email, uid: user.uid });
        if(id) {
          validatePayment();
        }
				// getEvents(user.uid, setEvents, setLoading);
        // addTicketToFirebase(true,event,id,-1,user.uid,name,email,router)

        // if(JSON.parse(data).code=="PAYMENT_SUCCESS"){
        //   // addTicketToFirebase(true,event,id,-1,user.uid,name,email,router);
        // }
			} else {
				return router.push("/register");
			}
		});
	}, []);

	useEffect(() => {
    // console.log(data);
    // if(JSON.parse(data).code=="PAYMENT_PENDING"){
    //   errorMessage("Payemnet Failed");
    //   router.push(`/register/${event}/test`);
    // }
		isUserLoggedIn();
	}, [isUserLoggedIn]);


    // const router = useRouter();
	// const {name, email,event } = router.query;
console.log(name,email);


  // useEffect(() => {
  //   addTicketToFirebase(true,event,id,-1,user.uid,name,email,router)
  //   // sendEmail(name,email);

  // });



	return (
    <div className="flex flex-col min-h-screen">
     
     
  <div className="flex-grow">
  <div className="w-full  flex flex-col items-center justify-center p-4">
  <img src={party} alt='Create an event' width={300} />

  </div>
<h2>
Please Don't Press Back
</h2>
			

      <h1>
        Your payment is being verified
      </h1>
      </div>
      
      <footer className="mt-auto py-4 bg-gray-200">
    <div className="text-center p-5">
      <b>Saola Innovations Private Limited</b>
    </div>
  </footer>
		</div>
	);
};

export default PaymentDone;
