import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/login'
import Register from './pages/register'
import Home from './pages/home';
import CreateEventPage from './pages/create/event';
import Dashboard from './pages/dashboard';
import Success from './pages/success';
import Failure from './pages/failure';

import UserPage from './pages/user';
import RegisterPage from './pages/register/[id]/[slug]'
import ListEvent from './pages/events/[id]/[slug]';
import UserRegisterPage from './pages/userRegister';
import { AuthContextProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute'
import { ToastContainer } from 'react-toastify';
import PaymentDone from './pages/paymentDone/[id]/[slug]'
function App() {
  return (
    <div className="App">
<ToastContainer/>

        <AuthContextProvider>


      <Routes>
      <Route path="/login" element={<Login/>} />
      <Route path="/register" element={<Register/>} />

      <Route path="/" element={<Home/>} />
      <Route path="/user" element={<UserPage/>} />



      {/* <Route path="/404" component={NotFoundPage} /> */}
        <Route path="/create/event" element={<CreateEventPage/>} />
        <Route path="/dashboard" element= {<ProtectedRoute><Dashboard/></ProtectedRoute> } />
        <Route path="/events/:id/:slug" element={<ListEvent/>} />
        <Route path="/register/:id/:slug" element={<RegisterPage/>} />
        <Route path="/userRegister" element={<UserRegisterPage/>} /> 
        <Route path="/success" element={<Success/>} /> 
        <Route path="/failure" element={<Failure/>} /> 

        <Route path="/paymentDone/:id/:slug" element={<PaymentDone/>} />
        {/* <Route path="/login" component={LoginPage} />
        <Route path="/paymentDone/:id/:slug" component={PaymentDonePage} />
        <Route path="/register" exact component={RegisterPage} />
       
        <Route path="/success" component={SuccessPage} />
        <Route path="/user" component={UserPage} />
        */}


      </Routes>

      </AuthContextProvider>

    </div>
  );
}

export default App;
