import React, { useState } from "react";
import { HiMail } from "react-icons/hi";
import { AiTwotoneLock } from "react-icons/ai";
// Assuming you're using react-router-dom for routing
import { useNavigate } from "react-router-dom";
// Replace firebaseLoginUser import path according to your project structure
import { firebaseLoginUser } from "../utils/util";
// Optional: react-helmet for managing document head
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const history = useNavigate(); // useHistory hook for navigation

	const handleSubmit = (e) => {
		e.preventDefault();
		firebaseLoginUser(email, password, history);
	};

	return (
		<div>
  <ToastContainer />

			{/* Using react-helmet to manage head */}
			<Helmet>
				<title>Login | Festum</title>
				<meta
					name="description"
					content="An event ticketing system built with React and Firebase"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="icon" href="/favicon.ico" />
			</Helmet>
			<main className="w-full flex items-center justify-between min-h-[100vh]">
				<div className="md:w-[60%] w-full flex flex-col items-center justify-center min-h-[100vh] px-[30px] py-[30px] relative">
					{/* Replace Link with anchor tag or react-router-dom Link */}
					<a href="/" className="text-2xl font-medium mb-6">
						Log into your account
					</a>
					<form className="w-full flex flex-col justify-center" onSubmit={handleSubmit}>
						<label htmlFor="email">Email address</label>
						<div className="w-full relative">
							<input
								type="email"
								name="email"
								className="border px-10 py-2 mb-3 rounded-md w-full"
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<HiMail className=" absolute left-4 top-3 text-gray-300 text-xl" />
						</div>
						<label htmlFor="password">Password</label>
						<div className="w-full relative">
							<input
								type="password"
								name="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className="border px-10 py-2 mb-4 rounded-md w-full"
								required
							/>
							<AiTwotoneLock className=" absolute left-4 top-3 text-gray-300 text-xl" />
						</div>
						<button
							type="submit"
							className="bg-[#FFD95A] p-3 font-medium hover:bg-[#C07F00] hover:text-[#FFF8DE] mb-3 rounded-md"
						>
							SIGN IN
						</button>
						<p className="text-center">
							Don't have an account?{" "}
							{/* Replace Link with anchor tag or react-router-dom Link */}
							<a href="/register" className="text-[#C07F00]">
								Register
							</a>
						</p>
					</form>
					<div className="absolute bottom-5 left-5">
						<p className="opacity-50 text-sm">
							{/* Replace Link with anchor tag or react-router-dom Link */}
							<a href="/">EventTiz</a> &copy; Copyright{" "}
							{new Date().getFullYear()}{" "}
						</p>
					</div>
				</div>
				<div className="login md:w-[40%] h-[100vh] relative">
					
				</div>
			</main>
		</div>
	);
};

export default Login;
