import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import {  } from "firebase/auth";
import { getAuth ,onAuthStateChanged as _onAuthStateChanged,EmailAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
	apiKey: "AIzaSyDwAZGEV2O_th1Wfibw7KoPaeTLplu3-wU",
	authDomain: "festum-saola.firebaseapp.com",
	projectId: "festum-saola",
	storageBucket: "festum-saola.appspot.com",
	messagingSenderId: "384742327265",
	appId: "1:384742327265:web:33212b6a78f9406ea188a0",
	measurementId: "G-66Y04H4XFZ"
  };


  

  

// Initialize Firebase
let app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
const provider = new EmailAuthProvider();
const storage = getStorage(app);
const db = getFirestore(app);
const rdb = getDatabase(app);

const auth = getAuth(app);

export function onAuthStateChanged(cb) {
	return _onAuthStateChanged(auth, cb);
}

export async function signInWithGoogle(url,navigate) {
  const provider = new GoogleAuthProvider();

  try {
    await signInWithPopup(auth, provider);
    navigate("/register/"+url);
  } catch (error) {
    console.error("Error signing in with Google", error);
  }
}

export async function signOut() {
  try {
    return auth.signOut();
  } catch (error) {
    console.error("Error signing out with Google", error);
  }
}

export { provider, auth, storage ,rdb};
export default db;
