import React, { useEffect, useState, useCallback } from "react";
import AuthNav from "../components/AuthNav";
import NoEvent from "../components/NoEvent";
import Events from "../components/Events";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../utils/firebase";
import { getEvents } from "../utils/util";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet";

const Dashboard = () => {
	const navigate = useNavigate();
	const [user, setUser] = useState({});
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);

	const isUserLoggedIn = useCallback(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser({ email: user.email, uid: user.uid });
				getEvents(user.uid, setEvents, setLoading);
			} else {
				navigate("/login");
			}
		});
	}, []);

	useEffect(() => {
// auth.signOut();
				isUserLoggedIn();
	}, [isUserLoggedIn]);

	if (loading) return <Loading title='Your dashboard is almost ready.🍚' />;
	return (
		<div>
		

			<Helmet>
			<title>Dashboard | Festum</title>
				<meta
					name='description'
					content='An event ticketing system built with NextJS and Firebase'
				/>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<link rel='icon' href='/favicon.ico' />
			</Helmet>
			<main>
				<AuthNav user={user} />
				{events.length > 0 ? <Events events={events} /> : <NoEvent />}
			</main>
		</div>
	);
};

export default Dashboard;
